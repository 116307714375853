body {
  margin: 0;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.full-height {
  height: 100%;
}
.leaflet-div-icon.leaflet-div-icon {
  border: none;
  background-color: unset;
}
